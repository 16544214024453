<template>
  <div class="">
    <div v-if="position">
      <button class="badge badge-red" style="padding: 5px 15px;margin-left: 2px" @click="deleteAllLeisureBetInfo">
        <i class="fa fa-close"> 전체삭제</i>
      </button>
    </div>
    <table class="table" v-for="(item,idx) in betList">
      <tr>
        <th style="width: 33%">종목</th>
        <th style="width: 33%">회차</th>
        <th>베팅시간</th>
      </tr>
      <tr>
        <td>{{item.kindConfig.kindName}}</td>
        <td>{{item.leisureGame.sequence}}</td>
        <td>{{item.createTime|datef('MM-DD HH:mm')}}</td>
      </tr>
      <tr>
        <th>배당</th>
        <th>베팅금</th>
        <th>예상적중금</th>
      </tr>
      <tr>
        <td class="text-skyblue">{{item.betOdds}}</td>
        <td class="text-skyblue">{{item.betCash|comma}}원</td>
        <td class="text-skyblue">{{item.totalCash|comma}}원</td>
      </tr>
      <tr class="">
        <th>내역</th>
        <td colspan="2" class="active_sports">
          <p>{{item.attributeConfig.attrName}}</p>
          <p style="font-weight: bold" v-if="item.attribute === leisureConst.LEISURE_ATTRIBUTE_LOTTO_POWERBALL">{{item.selectedValue}}</p>
          <p style="font-weight: bold" v-if="item.attribute !== leisureConst.LEISURE_ATTRIBUTE_LOTTO_POWERBALL">{{item.selectedValueText}}</p>
          <p style="font-weight: bold" v-if="item.leisureGame.homeTeamName != null">{{item.leisureGame.homeTeamName + ' vs '+item.leisureGame.awayTeamName}}</p>
        </td>
      </tr>
      <tr class="">
        <th>
          <input v-if="position" type="checkbox"
                     @click="checkBetId(item.id)">
          결과
        </th>
        <td style="font-weight: bold;font-size: 14px;">
          <span v-if="item.betResult === sportsConst.BET_RESULT_WIN" class="text-skyblue">당첨</span>
          <span v-if="item.betResult === sportsConst.BET_RESULT_LOSE" class="text-red">낙첨</span>
          <span v-if="item.betResult === sportsConst.BET_RESULT_SPECIALCASE" class="text-orange">적특</span>
          <span v-if="item.betResult === sportsConst.BET_RESULT_CANCEL" class="text-red">취소</span>
          <span v-if="item.betResult === sportsConst.BET_RESULT_WAITING" class="text-white">대기중</span>
        </td>
        <td class="btns">
          <!--<button class="btn01 text-red" v-if="item.betResult === sportsConst.BET_RESULT_WAITING && position"
                  @click="cancelBet(item.id)"> <i class="fa fa-close"> 취소</i>
          </button>-->
          <button class="badge badge-red" v-if="item.betResult !== sportsConst.BET_RESULT_WAITING && position"
                  @click="deleteBet(item.id)"> <i class="fa fa-close"> 삭제</i>
          </button>
        </td>
      </tr>
    </table>

  </div>
</template>

<script>
  import sportsConst from "../../common/sportsConst";
  import leisureConst from "../../common/leisureConst";
  import {deleteAllLeisureBet} from "../../network/leisureRequest";

  export default {
    name: "LeisureBetListComp",
    props: {
      betList: {
        type: Array,
        default() {
          return []
        }
      },
      position: {
        type: Number,
        default: 1 // 0:게시글에등록된 베팅내역(삭제,취소,선택체크 를 숨김) 1: 베팅내역 리스트
      }
    },
    data() {
      return {
        sportsConst,
        leisureConst,
        betIds: [],
      }
    },
    methods: {
      deleteAllLeisureBetInfo() {
        this.$swal({
          title: '전체 베팅내역을 삭제하시겠습니까?',
          type: 'question',
          confirmButtonText: ' 예 ',
          cancelButtonText: '아니오'
        }).then((res) => {
          if (res.value) {
            deleteAllLeisureBet().then(res => {
              if (res.data.success) {
                this.$emit('cancelOrDelBet')
              } else {
                this.$swal({
                  title: res.data.msg,
                  type: 'error',
                  showCancelButton: false,
                  showConfirmButton: true
                })
              }
            })
          }
        })
      },
      checkBetId(id) {
        let index = this.betIds.findIndex((item) => {
          return item === id
        });
        if (index === -1) {
          this.betIds.push(id)
        } else {
          this.betIds.splice(index, 1)
        }
        this.$emit('betInfoChecked', this.betIds)
      },
      deleteBet(id) {
        this.$swal({
          title: '해당 베팅내역을 삭제하시겠습니까?',
          type: 'question',
          confirmButtonText: ' 예 ',
          cancelButtonText: '아니오'
        }).then((res) => {
          this.betInfo = {selectedVals: []}
          if (res.value) {
            this.$emit('deleteBet', id)
          }
        })
      },
      cancelBet(id) {
        this.$swal({
          title: '해당 베팅내역을 취소하시겠습니까?',
          type: 'question',
          confirmButtonText: ' 예 ',
          cancelButtonText: '아니오'
        }).then((res) => {
          this.betInfo = {selectedVals: []}
          if (res.value) {
            this.$emit('cancelBet', id)
          }
        })
      }
    }
  }
</script>

<style scoped>
  table {
    border: 2px solid #45464c;
    border-radius: 0px;
    margin: 10px auto;
    font-size: 12px;
  }

  table th{
    color: #fff9ff;
    height: 30px;
    line-height: 30px;

  }
  table td{
    height: 30px;
    line-height: 30px;
  }

</style>